import { Show, createSignal, createEffect, onMount } from 'solid-js';
import Auth from './Auth'
import authSession from './authSession';
import Routes from './Routes';
import posthog from 'posthog-js'
// import LegalsCheck from "./LegalsCheck";
import addIntercom from './addIntercom'
import createUser from './createUser'
import * as Sentry from "@sentry/browser";
import { supabase } from '../supabaseClient'


const App = () => {

  const [shouldAuth, setShouldAuth] = createSignal(true);
  const [newSignup, setNewSignup] = createSignal(false);
  const [userId, setuserId] = createSignal(null);
  const [userEmail, setUserEmail] = createSignal(null);

  onMount(() => {
    authSession(userId, setuserId, userEmail, setUserEmail);
  });

  function requireAuth(route) {
    if (userId()) return false
    if(route === 'deployed-app') return false
    if(route === 'landing-page') return false
    if(route === 'bundle') return false
    if(route === 'create-new-app') return false
    if(window.location.hostname !== 'home.zapt.ai' && window.location.hostname !== 'schools.zapt.ai' && window.location.hostname !== 'davidmfox-cautious-space-enigma-69pgqwrxrqjf5wjq-8000.app.github.dev') return false
    return true
  }

  createEffect(() => {
    if(posthog.__loaded && userId() && userEmail()) posthog.identify(userId(), { email: userEmail() });

    let route = window.location.pathname.split('/')[1]

    // let shouldAuthenticate = !userId() && (route !== 'deployed-app');
    let shouldAuthenticate = requireAuth(route)
    setShouldAuth(shouldAuthenticate);
  });

  async function initializeUser() {
    try {
        if (!userId() || !userEmail()) return;
        await createUser();
        await addIntercom(userEmail());
        if(window.progressier) progressier.add({id: userId()});
    } catch (error) {
        Sentry.captureException(error);
    }
  }

  createEffect(initializeUser);

  const [appId, setAppId] = createSignal(null);
  const [domainChecked, setDomainChecked] = createSignal(false);
  const [bundleId, setBundleId] = createSignal(null);

  function getAppId(app_id) {
    if (app_id) return app_id;
    let path = window.location.pathname.split('/'); // always starts with '/'
    if(path[1] === 'bundle') return null;
    if (path.length < 3) return null;
    return path[2];
  }

  function getBundleId(bundle_id) {
    if (bundle_id) return bundle_id;
    let path = window.location.pathname.split('/'); // always starts with '/'
    if(path[1] !== 'bundle') return null;
    if (path.length < 3) return null;
    return path[2];
  }

  const [customDomain, setCustomDomain] = createSignal(null);

  async function checkDomain() {
    try {
      const url = window.location.hostname;

      const { data, error } = await supabase.from("CustomDomain").select('app_id, is_app, is_landing_page, bundle_id, is_bundle').eq('value', url);
      if (error) throw error;
      setCustomDomain(data[0]);
      if (data.length == 0){
        setAppId(getAppId());
        setBundleId(getBundleId());
        setDomainChecked(true);
        return;
      }
      setAppId(getAppId(data[0].app_id));
      setBundleId(getBundleId(data[0].bundle_id));
      setDomainChecked(true);
      return
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      return;
    }
  }

  createEffect(checkDomain);

  return (
    <Show when={!shouldAuth()} fallback={
      <Show when={domainChecked()}>
        <Auth app_id={appId()} bundle_id={bundleId()} newSignup={newSignup} />
      </Show>
    }>
{/*
      <Show when={userId()}>
        <LegalsCheck user_id={userId()} />
      </Show>
*/}

      <Routes userId={userId} setShouldAuth={setShouldAuth} shouldAuth={shouldAuth} customDomain={customDomain} domainChecked={domainChecked} setNewSignup={setNewSignup} />

    </Show>
  )
}

export default App
