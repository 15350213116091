import { onCleanup } from 'solid-js';
import { supabase } from '../supabaseClient';

export default function setupAuthSession(userId, setUserId, userEmail, setUserEmail) {

  function updateUserData(user) {
    if (user.id !== userId()) {
      setUserId(user.id);
    }
    if (user.email !== userEmail()) {
      setUserEmail(user.email);
    }
  }

  // Fetch the current session
  supabase.auth.getSession().then(({ data }) => {
    if (data?.session) {
      updateUserData(data.session.user);
    }
  });

  let hasLoggedSession = false;

  const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
    console.log('auth event:', event);
    if (session) {
      updateUserData(session.user);
  
      // Only log "session created" if it's the first time
      if (!hasLoggedSession) {
        console.log('session created');
        hasLoggedSession = true;
      }
    } else {
      setUserId(null);
      setUserEmail(null);
      hasLoggedSession = false;
    }
  });  

  // Clean up when the component unmounts
  onCleanup(() => {
    authListener.unsubscribe();
  });
}
